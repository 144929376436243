import React from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import Button from '../components/button'

const Wrapper = styled.div`
  position: relative;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 50px;

  ${up('sm')} { padding-top: 50px; }
`

const Title = styled.h1`
    color: black;
    margin: 50px 0;
    text-align: center;
    font-size: 100px;
    line-height: 95px;

    ${up('sm')} {
      font-size: 200px;
      line-height: 190px;
    }
`

const Description = styled.div`
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;

    ${up('sm')} {font-size: 18px; }
`

const NotFoundPage = () => (
  <Wrapper data-scroll-wrapper>
    <Container>
      <Row>
        <Col>
          <Title>404</Title>
          <Description>Page non trouvée</Description>
          <Button to='/'>{`Retour`}</Button>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

export default NotFoundPage
